<template>
  <div class="col-12">
    <b-card
      class="profile-header mb-2 p-0 m-0"
      alt="cover photo"
      body-class="p-0 mb-0 mb-md-5"
    >
      <template #header>
        <div class="image-gradient" :style="background_image">
        </div>
      </template>
      <!-- profile picture -->
      <div class="profile-container-preview d-flex">
        <b-img
          :src="influencer ? influencer.photo_url : ''"
          variant="light-primary"
          class="profile-img-preview"
          @error="handleErrorImage"
        />
        <div class="container-profile-username-preview d-none d-md-block">
          <p class="text-white font-weight-bold d-flex flex-wrap" target="_blank">
            <span class="mr-1">@{{influencer.username}} <image-verified v-if="influencer.is_verified" class="iconCheck"/></span>
            <span class="mr-1" v-if="influencer.location"><feather-icon icon="MapPinIcon" class="mr-05"></feather-icon>{{influencer.location}}</span>
            <span class="mr-1"><feather-icon icon="BookOpenIcon" class="mr-05"></feather-icon>
              <span v-for="(language, index) in influencer.blogger_languages" :key="index">
                {{language}}
              </span>
            </span>
            <span v-for="(category, index) in influencer.blogger_categories" :key="index">
              <b-badge pill variant="secondary" v-if="checkNumber(category)">{{getNameCategory(parseInt(category))}}</b-badge>
              <b-badge pill variant="secondary" v-else>{{category}}</b-badge>
            </span>
          </p>
          <h3 class="text-white">{{capitalize(network)}} {{$t('preview.stats_for')}} {{influencer.full_name}} (@{{influencer.username}})</h3>
          <b-row class="d-none d-xl-flex">
            <b-col>
              <span class="text-muted">{{$t('campaigns.followers')}}</span>
              <h3 class="text-white">{{influencer.followers ? getFormat(influencer.followers) : '0'}}</h3>
            </b-col>
            <b-col v-if="influencer.er !== null">
              <span class="text-muted">{{$t('profile.er')}}</span>
              <h3 class="text-white">{{influencer.er ? decimalFixed(influencer.er, 2) : 0}}%</h3>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="container-buttons-preview d-none d-xl-block" ref="container_buttons">
        <b-row class="justify-content-between flex-wrap px-1">
          <b-button class="mb-1 mr-1 w-100 w-md-auto" variant="primary" @click="$router.push({name: 'auth-login'})">
            <feather-icon icon="MailIcon" ></feather-icon>
            {{$t('preview.send_email')}}
          </b-button>
          <b-button class="mb-1 mr-1 outline-primary-fill w-100 w-md-auto" variant="outline-primary-fill" @click="$router.push({name: 'auth-login'})">
            <feather-icon icon="MailIcon" ></feather-icon>
            PDF
          </b-button>
          <b-button class="mb-1 mr-1 w-100 w-md-auto" variant="outline-secondary" @click="$router.push({name: 'auth-login'})">
            <feather-icon icon="FileTextIcon" ></feather-icon>
            {{$t('search.add_to_list')}}
          </b-button>
          <b-button class="mb-1 mr-1 w-100 w-md-auto" variant="outline-secondary" @click="$router.push({name: 'auth-login'})">
            <feather-icon icon="PlusIcon" ></feather-icon>
            {{$t('search.add_to_campaign')}}
          </b-button>
        </b-row>
      </div>
      <feather-icon
        icon="AlignJustifyIcon"
        size="21"
        class="d-block d-xl-none align-menu-toggle"
        @click="toggleNav"
      />
      <div class="mt-4 mb-2 text-center d-block d-md-none">
        <h2 class="font-weight-bold d-flex justify-content-center flex-wrap" target="_blank">
          <span class="mr-1">{{influencer.username}} <image-verified v-if="influencer.is_verified" class="iconCheck"/></span>
        </h2>
      </div>
    </b-card>
  </div>
</template>

<script>
import { 
  BCard,
  BRow,
  BCol,
  BBadge,
  BButton,
  BImg,
} from "bootstrap-vue";
import service_profile from '@/services/profile';
import { isUrl, getAssetUrl } from "@/libs/utils/urls";
import { urlImagetoBase64, capitalize, getFormat, decimalFixed, checkNumber } from "@/libs/utils/formats";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BBadge,
    BButton,
    BImg,
    ImageVerified: () => import('@/views/components/image/ImageVerified.vue'),
  },
  props: {
    influencer: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      capitalize,
      getFormat,
      decimalFixed,
      checkNumber,
      network: this.$route.params.network,
      background_image: '',
    }
  },
  created() {
    this.getCover();
  },
  methods: {
    toggleNav() {
      this.$refs.container_buttons.classList.toggle('d-none')
    },
    handleErrorImage() {
      this.influencer.photo_url = require("@/assets/images/dashboard/imgDefault.png");
    },
    getCover() {
      service_profile.getCover().then((response) => {
        let image_header = '';
        if (response.cover_url) {
          if (isUrl(response.cover_url)) {
            image_header = response.cover_url;
          } else {
            image_header = getAssetUrl(response.cover_url);
          }
          const self = this;
          urlImagetoBase64(image_header, (result) => {
            self.background_image = {
              "background-image": `linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%), url('${result}')`,
            }
          });
        } else {
          this.background_image = {
            "background-image": `linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%), url()`,
          }
        }
      })
    },
    getNameCategory(category) {
      let entry = false
      switch (this.network) {
      case 'instagram':
        entry = this.$t('instaCategories').find((e) => e.value === category);
        if (entry) return entry.text
        break;
      case 'youtube':
        entry = this.$t('youtubeCategory').find((e) => e.value === category);
        if (entry) return entry.text
        break;

      case 'tiktok':
        entry = this.$t('tiktokCategory').find((e) => e.value === category);
        if (entry) return entry.text
        break;
      }
      return ''
    },
  }
};
</script>
<style>
.container-avatar-table {
  position: relative;
}
.image-gradient {
  width: 100%;
  height: 250px;
  background-size: cover;
}
.profile-header > .card-header {
  padding: 0px;
}
.profile-container-preview {
  position: absolute;
  top: 9em;
  left: 3em;
}
.container-profile-username-preview {
  color: white !important;
}
.container-buttons-preview {
  position: absolute;
  bottom: -0.3em;
  left: 16em;
}
.profile-img-preview {
  padding: 0.5em !important;
  background-color: #ebe9f1;
  width: 150px;
  height: 150px;
  border-radius: 0.5em;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  margin: 0 2em 0 0;
}
.align-menu-toggle {
  position: absolute;
  right: 1em;
  top: 19.3em;
  cursor: pointer;
}
.container-avatar-table-icon {
  position: absolute; 
  bottom: -5px; 
  right: -15px;
}
.sidebar-icon {
  font-size: 18px;
  margin-right: 15px;
}
.style-border-icon {
  font-size:1em; 
  border-radius: 50%; 
  background: white; 
  padding: 2px
}
.contents-icon {
  font-size: 12px;
}
.light-secondary-v2 {
  background-color: #EFF3F6;
  color: #718096;
  font-weight: 400;
  margin: 0 0.2em;
}
.mr-05 {
  margin-right: 0.5em;
}
@media(min-width: 768px) {
  .border-md-right {
    border-right: 1px solid #ebe9f1 !important;
  }
  .w-md-auto {
    width: auto !important;
  }
}
@media(max-width: 1200px) {
  .container-buttons-preview {
    width: 100%;
    top: 21em;
    bottom: auto;
    background-color: white;
    padding: 1em;
    left: 0;
    z-index: 10;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    border-end-start-radius: 1em;
    border-end-end-radius: 1em;
  }
}
@media(max-width: 767px) {
  .profile-container-preview {
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
